import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const token = getUserAccessToken();
    return !!token;
};

const getLoginId = () => {
    const cookies = new Cookies();
    return cookies.get('loginId');
};

const getLoginData = () => {
    const cookies = new Cookies();
    const loginData = cookies.get('loginData');
    return loginData ? (typeof loginData == 'object' ? loginData : JSON.parse(loginData)) : {};
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const getUserAccessToken = () => {
    // console.log('getUserAccessToken');
    const cookies = new Cookies();
    const tokens = cookies.get('tokens');
    // console.log('tokens', tokens);
    return tokens ? (typeof tokens == 'object' ? tokens.access : JSON.parse(tokens).access) : null;
};

export { isUserAuthenticated, getLoggedInUser, getUserAccessToken, getLoginId, getLoginData };
