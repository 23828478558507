import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import { changeLayoutTitle } from '../../redux/actions';
import BaseAPI from '../../helpers/base-api';
import EdgeToast from '../../components/EdgeToast';
import { Helmet } from 'react-helmet';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
    '[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-057526}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{31 May 2024}____[v3]_[0102]_MTcxNzExMDAwMDAwMA==7248f424acb603bf964495a90204f0ef'
);

const adminAPI = new BaseAPI();

const GalileoTransactionsPage = () => {
    const { prn } = useParams();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const dispatch = useDispatch();

    const [columnDefs] = useState([
        { field: 'auth_id', headerName: 'Auth ID', rowGroup: true, hide: false },
        { field: 'details', headerName: 'Source' },
        { field: 'amt', headerName: 'Amount', type: 'numericColumn', width: 35 },

        { field: 'post_ts', headerName: 'Date', sort: 'desc' },

        { field: 'act_type_description', headerName: 'Type' },
        // { field: 'trans_code', headerName: 'Transaction Code', width: 50 },
        { field: 'calculated_balance', headerName: 'Ending Balance', type: 'numericColumn', width: 50 },
    ]);

    const [gridOptions] = useState({
        defaultColDef: {
            flex: 1,
            minWidth: 10,
            resizable: true,
            sortable: true,
        },
        pagination: true,
        paginationPageSize: 200,
        groupDefaultExpanded: 1,
        autoGroupColumnDef: {
            headerName: 'Auth ID',
            minWidth: 200,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                checkbox: true,
            },
        },
    });

    useEffect(() => {
        dispatch(changeLayoutTitle('Galileo Transactions'));
        fetchTransactions();
    }, [dispatch, prn]);

    const fetchTransactions = async () => {
        try {
            setLoading(true);
            const response = await adminAPI.get(`galileo/transaction-history/${prn}`);
            console.log(response.data.response_data.transactions);
            setTransactions(response.data.response_data.transactions);
            setLoading(false);
        } catch (error) {
            showMessageToast('danger', 'Error', 'Failed to fetch transactions');
            setLoading(false);
        }
    };

    const toggleToast = () => setShowToast(!showToast);

    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const handleRefresh = () => {
        fetchTransactions();
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>Admin - Galileo Transactions</title>
            </Helmet>
            <section className="section mt-4">
                <Card>
                    <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3 className="header-title subtitle inside-header">Galileo Transactions for PRN: {prn}</h3>
                            <br />
                            <h6> Only Displaying the most recent 200</h6>
                            <Button color="primary" onClick={handleRefresh}>
                                Refresh
                            </Button>
                        </div>
                        <div className="ag-theme-alpine" style={{ height: 900, width: '100%' }}>
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={transactions}
                                gridOptions={gridOptions}
                                // onGridReady={(params) => params.api.sizeColumnsToFit()}
                                animateRows={true}
                                groupDisplayType={'groupRows'}
                            />
                        </div>
                    </CardBody>
                </Card>
            </section>
            <EdgeToast
                isOpen={showToast}
                toggle={toggleToast}
                type={toastType}
                title={toastTitle}
                content={toastContent}
            />
        </React.Fragment>
    );
};

export default GalileoTransactionsPage;
