import React, { useState } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TransactionDetails from './TransactionDetails';
import { formatDate, currencyFormatter, convertTransactionTypesToFriendly } from '#helpers/displayUtils.js';
const TransactionGrid = ({ transactions }) => {
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [showModal, setShowModal] = useState(false);
    // console.log('transactions', transactions);

    // these are the columns we're going to display in the grid
    const columns = [
        {
            headerName: 'Date',
            field: 'createdAt',
            sortable: true,
            filter: 'agDateColumnFilter',
            valueFormatter: ({ value }) => formatDate(value),
        },
        {
            headerName: 'Type',
            field: 'type',
            filter: true,
            valueGetter: (params) => convertTransactionTypesToFriendly(params.data.type),
        },
        { headerName: 'Status', field: 'status', filter: true },
        { headerName: 'Source', field: 'sourceText', filter: false, sortable: false },
        // {
        //     headerName: 'Merchant',
        //     field: 'authorization.merchant.merchant_description',
        //     filter: false,
        //     sortable: false,
        // },
        {
            headerName: 'Net Amount',
            field: 'netAmount',
            filter: true,
            valueFormatter: currencyFormatter,
        },
        {
            headerName: 'Fee',
            field: 'fee',
            sortable: true,
            filter: true,
            valueFormatter: currencyFormatter,
        },
    ];

    const toggleTransactionDetailModal = () => {
        setShowModal(!showModal);
    };

    const onRowClicked = (event) => {
        setSelectedTransaction(event.data);
        toggleTransactionDetailModal();
    };

    const defaultColDef = {
        flex: 1,
        minWidth: 110,
        // filter: true,
        resizable: true,
        autoHeight: true,
    };

    return (
        <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
            <AgGridReact
                rowData={transactions}
                columnDefs={columns}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                animateRows={true}
                pagination={true}
                paginationPageSize={20}
                onRowClicked={onRowClicked}
                enableAdvancedFilter={true}
            />
            <Modal isOpen={showModal} toggle={toggleTransactionDetailModal} size="lg">
                <ModalHeader toggle={toggleTransactionDetailModal}>Transaction Details</ModalHeader>
                <ModalBody>{selectedTransaction && <TransactionDetails transaction={selectedTransaction} />}</ModalBody>
            </Modal>
        </div>
    );
};

export default TransactionGrid;
