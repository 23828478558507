import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import BaseAPI from '#helpers/base-api.js';
import { FormGroup, Label, Input, Button, Col } from 'reactstrap';

const EditAchLimitPage = () => {
    const ebApiClient = new BaseAPI();
    const { ach_limit_id } = useParams();
    const history = useHistory();
    const [achLimitData, setAchLimitData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // Assuming ebApiClient is set up elsewhere and ach_limit_id is passed to the function or globally available
    async function fetchAchLimit(ach_limit_id) {
        setIsLoading(true);
        try {
            console.log('Fetching achLimit with ID:', ach_limit_id);

            const params = {
                filter: JSON.stringify({ _id: ach_limit_id }),
            };

            const response = await ebApiClient.get('ach-limits', params);

            if (response.data.results.length > 0) {
                const limitData = response.data.results[0];
                setAchLimitData({
                    ...limitData,
                    amount: (limitData.amount || 0) / 100,
                });
            } else {
                console.log('No ACH Limit data found for ID:', ach_limit_id);
            }
        } catch (error) {
            console.error('Failed to fetch ACH Limit with ID:', ach_limit_id, error);
            // Optionally, handle different types of errors differently
            // e.g., if (error.response && error.response.status === 404) { ... }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchAchLimit(ach_limit_id);
    }, [ach_limit_id]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAchLimitData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleUpdate = async () => {
        console.log('Update payload:', achLimitData);
        try {
            const response = await ebApiClient.put(`ach-limits/${achLimitData.id}`, {
                ...achLimitData,
                amount: (achLimitData.amount || 0) * 100,
            });
            console.log('Update response:', response.data);
            history.push('/settings/list/ach-limits'); // Redirect or handle post-update logic
        } catch (error) {
            console.error('Failed to update achLimit', error);
        }
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <div>
            <h1>Edit AchLimit</h1>

            <FormGroup row>
                <Label sm={4}>Time Frame:</Label>
                <Col sm={8}>
                    <Input
                        type="select"
                        className="form-control"
                        name="timeframe"
                        value={achLimitData.timeframe}
                        onChange={handleInputChange}
                        disabled>
                        <option value=""></option>
                        <option value="daily">Daily</option>
                        <option value="monthly">Monthly</option>
                        <option value="per">Per Transaction</option>
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={4}>Scope:</Label>
                <Col sm={8}>
                    <Input
                        type="select"
                        className="form-control"
                        name="scope"
                        value={achLimitData.scope}
                        onChange={handleInputChange}
                        disabled>
                        <option value=""></option>
                        <option value="system">System</option>
                        <option value="individual">Individual</option>
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={4}>Transaction Type:</Label>
                <Col sm={8}>
                    <Input
                        type="select"
                        className="form-control"
                        name="transactionType"
                        value={achLimitData.transactionType}
                        onChange={handleInputChange}
                        disabled>
                        <option value=""></option>
                        <option value="deposit">Deposit</option>
                        <option value="withdrawal">Withdrawal</option>
                    </Input>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={4}>Amount ($):</Label>
                <Col sm={8}>
                    <Input type="number" name="amount" value={achLimitData.amount} onChange={handleInputChange} />
                </Col>
            </FormGroup>
            <Button onClick={handleUpdate}>Update ACH Limit</Button>
        </div>
    );
};

export default EditAchLimitPage;
