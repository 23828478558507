import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Button,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    FormGroup,
    Input,
} from 'reactstrap';
import BaseAPI from '../../../helpers/base-api';
import Enums from '../../../constants/enums';
import CloseModalButton from '#components/CloseModalButton.js';

const baseAPI = new BaseAPI();

const CardStatusChangeModal = ({ isOpen, onClose, onYes, card = {} }) => {
    const [disabled, setDisabled] = useState(false);
    const [updateDisabled, setUpdateDisabled] = useState(true);
    const [cardStatus, setCardStatus] = useState('');
    const [reason, setReason] = useState('');

    useEffect(() => {
        setCardStatus(card.cardStatus);
        setReason(card.reasonForInactive || '');
    }, [card.prnNumber]);

    const handleStatusChange = (e) => {
        setCardStatus(e.target.value);
        setUpdateDisabled(card.cardStatus == e.target.value);
    };

    const handleUpdate = async (e) => {
        onYes(cardStatus, reason);
    };

    return (
        <Modal isOpen={isOpen} toggle={onClose} backdrop="static">
            <ModalHeader toggle={onClose} close={<CloseModalButton onClose={onClose} />}>
                Change Galileo Card Status
            </ModalHeader>
            <ModalBody>
                <FormGroup row>
                    <Label sm={4}>PRN: </Label>
                    <Label sm={8}>{card?.prnNumber}</Label>
                </FormGroup>
                <FormGroup row>
                    <Label sm={4}>Card Number: </Label>
                    <Label sm={8}>{card?.cardNumber}</Label>
                </FormGroup>
                <FormGroup row>
                    <Label for="card-status" sm={4}>
                        Card Status:{' '}
                    </Label>
                    <Col sm={8}>
                        <Input type="select" id="card-status" onChange={handleStatusChange} value={cardStatus}>
                            <option value="N">Active</option>
                            <option value="D">Disabled</option>
                        </Input>
                    </Col>
                </FormGroup>
                {cardStatus == Enums.GalileoCardStatus.DISABLED && (
                    <FormGroup row>
                        <Label for="reason-text" sm={4}>
                            Reason for Disabling:{' '}
                        </Label>
                        <Col sm={8}>
                            <Input
                                type="textarea"
                                id="reason-text"
                                onChange={(e) => setReason(e.target.value)}
                                value={reason}
                                rows={4}
                            />
                        </Col>
                    </FormGroup>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleUpdate} disabled={updateDisabled}>
                    Update
                </Button>{' '}
                <Button color="secondary" onClick={onClose} disabled={disabled}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CardStatusChangeModal;
