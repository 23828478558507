import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import BaseAPI from '#helpers/base-api.js';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { changeLayoutTitle } from '../../redux/actions.js';
import EdgeToast from '#components/EdgeToast.js';
import Enums from '../../constants/enums.js';
import { set } from 'date-fns';
import { Table, Badge, Modal, ModalHeader, ModalBody, Card } from 'reactstrap';

const GalileoCard = ({ cardUrl, issueCard, showNumber, setShowNumber }) => {
    if (cardUrl) {
        return (
            <Card className={`view-card mb-0 mr-0`}>
                <img src={cardUrl} alt="Galileo Betting Card" />
            </Card>
        );
    } else {
        return <h2>Loading...</h2>;
    }
};

const GalileoUserDetailsPage = () => {
    const ebAdminClient = new BaseAPI();
    const { user_id } = useParams();
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastTitle, setToastTitle] = useState('');
    const [toastContent, setToastContent] = useState('');
    const toggleToast = () => setShowToast(!showToast);

    const [galileoCards, setGalileoCards] = useState([]);

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const [showNumber, setShowNumber] = useState(true);

    const showMessageToast = (type, title, content) => {
        setToastType(type);
        setToastTitle(title);
        setToastContent(content);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 10000);
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeLayoutTitle('Galileo Details'));
        getGalileoCards(user_id);
    }, [user_id]);

    async function getGalileoCards() {
        const response = await ebAdminClient.get(`users/${user_id}/galileo-cards`);
        if (response.data) {
            setGalileoCards(response.data);
            console.log('Galileo cards', response.data);
        } else {
            console.log('No Galileo cards found for this user', user_id);
        }
    }

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'Y':
                return 'success';
            case 'L':
                return 'warning';
            case 'N':
                return 'success';
            default:
                return 'success';
        }
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case 'Y':
                return 'Active';
            case 'L':
                return 'Lost/Stolen';
            case 'N':
                return 'Normal';
            default:
                return 'Unknown';
        }
    };

    const [cardViewModalOpen, setCardViewModalOpen] = useState(false);
    const [cardUrl, setCardUrl] = useState('');

    const handleViewCard = async (cardId, card) => {
        try {
            const response = await ebAdminClient.get(`users/${user_id}/cards/${cardId}/view`);
            console.log('response', response);
            if (response.data?.card_image_url) {
                setCardUrl(response.data.card_image_url.url);
                setCardViewModalOpen(true);
            } else {
                showMessageToast('error', 'Error', 'Unable to load card details');
            }
        } catch (error) {
            console.error('Error fetching card details:', error);
            showMessageToast('error', 'Error', 'Failed to load card details');
        }
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className="py-3">
            <Helmet>
                <title>Galileo Details {userData.email ? `${userData.email} Details` : 'Loading...'}</title>
            </Helmet>

            {/* Enhanced User Details Section */}
            <Card className="mb-4">
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h2 className="mb-0">User Details</h2>
                        <button
                            className="btn btn-warning"
                            onClick={() =>
                                showMessageToast('info', 'Coming Soon', 'Card creation feature is coming soon!')
                            }>
                            Add New Card
                        </button>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">Full Name</h6>
                            <h5>
                                {galileoCards?.customer?.first_name} {galileoCards?.customer?.last_name}
                            </h5>
                        </div>
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">PRN</h6>
                            <h5>{galileoCards?.accounts?.[0]?.pmt_ref_no || 'N/A'}</h5>
                        </div>
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">Account Number</h6>
                            <h5>{galileoCards?.accounts?.[0]?.galileo_account_number || 'N/A'}</h5>
                        </div>
                        <div className="col-md-6 mb-3">
                            <h6 className="text-muted mb-1">Total Cards</h6>
                            <h5>
                                {galileoCards?.accounts?.reduce((total, account) => total + account.cards.length, 0) ||
                                    0}
                            </h5>
                        </div>
                    </div>
                </div>
            </Card>

            {/* Cards Table Section */}
            {galileoCards?.accounts?.map((account) => (
                <div key={account.galileo_account_number} className="mt-4">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4>Galileo Cards</h4>
                    </div>
                    <Table striped responsive className="mb-5">
                        <thead>
                            <tr>
                                <th>Card Number</th>
                                <th>Status</th>
                                <th>Created Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {account.cards.map((card) => (
                                <tr key={card.card_id}>
                                    <td>{card.card_number}</td>
                                    <td>
                                        <Badge color={getStatusBadgeColor(card.card_status)}>
                                            {getStatusLabel(card.card_status)}
                                        </Badge>
                                    </td>
                                    <td>{account.application_date}</td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() => handleViewCard(card.card_id)}>
                                            View Card
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            ))}

            {/* Card View Modal */}
            <Modal isOpen={cardViewModalOpen} toggle={() => setCardViewModalOpen(false)}>
                <ModalHeader toggle={() => setCardViewModalOpen(false)}>Card Details</ModalHeader>
                <ModalBody>
                    <GalileoCard
                        cardUrl={cardUrl}
                        issueCard={{ cardStatus: 'Y' }}
                        showNumber={showNumber}
                        setShowNumber={setShowNumber}
                    />
                </ModalBody>
            </Modal>

            {/* Toast Notifications */}
            <EdgeToast
                isOpen={showToast}
                toggle={toggleToast}
                type={toastType}
                title={toastTitle}
                content={toastContent}
            />
        </div>
    );
};

export default GalileoUserDetailsPage;
